<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="300"
    max-width="600"
    content-class="user-notification-menu-content"
  >
    <template 
      v-slot:activator="{ on, attrs }"
    >
      <v-badge
        top
        color="error"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        :content="$store.state.unread_user_notifications"
      >
        <v-btn
          @click="markNotificationRead()"
          icon
          small
          class="ms-3"
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            @click="markNotificationRead()"
          >
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-0">
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            Notifications
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item link  :id="'notification_'+item.id" v-for="item in $store.state.user_notifications" :key="item.id">
        <v-list-item-content>
          <v-list-item-title class="mt-3 text-caption mb-0 py-0" >
            <v-row>
              <v-col
                cols="10" sm="10"
                class="text-left pb-0 mt-0"
              >
                {{item.verb}}: ({{ item.get_date_str }})
              </v-col>
              <v-col
                cols="1"
                class="text-right pb-0 mt-0"
              >
              <v-btn class="mr-2 mt-0 pt-0" color="dark" text small @click="deleteNotification(item.id)">
                  <p class="mt-0 pt-0">X</p>
              </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-list-item-subtitle 
            class="text-caption"
            v-html="item.description"
            style="border-bottom: 1px solid grey"
          >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiBellOutline,
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import axios from 'axios'

export default {
  data: () => ({
    icons: {
        mdiBellOutline,
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },

  }),
  methods: {

    async deleteNotification(notification_id){
        console.log("deleteNotification")
        let formData = new FormData()
        formData.append('notification_id', notification_id)
        await axios
            .post('/api/v1/home/delete/user/notifications/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(response => {
                console.log(response.data)
                var notification = document.getElementById("notification_"+notification_id)
                notification.classList.add("delete_diplay")

                this.$store.commit('setSnackBarMessage', "Notification deleted successfully")
                this.$store.commit('setSnackBarColor', "teal darken-4")
                this.$store.commit('activateSnackBar', true)

            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

    async markNotificationRead(){
        this.$store.commit('setIsLoading', true)
        await axios
            .get('/api/v1/home/mark/user/notifications/read/')
            .then(response => {
                console.log(response.data)
                this.$store.state.unread_user_notifications = 0  // get the data and fill into campaigns
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
        this.$store.commit('setIsLoading', false)
    },
    async getUserNotifications() {
        await axios
            .get('/api/v1/home/get/user/notifications/')
            .then(response => {
                this.$store.state.user_notifications = response.data[0]  // get the data and fill into campaigns
                this.$store.state.unread_user_notifications = response.data[1]  // get the data and fill into campaigns
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },

  }
}
</script>

<style lang="scss">
    
  .delete_diplay{
      display: none;
  }    

  .user-notification-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
