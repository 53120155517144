<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app fixed elevate-on-scroll>
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <v-text-field rounded dense outlined :prepend-inner-icon="icons.mdiMagnify" class="app-bar-search flex-grow-0"
            hide-details></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <a>
            <v-icon class="ms-2 me-2"> </v-icon>
          </a>
          <app-bar-notification-menu></app-bar-notification-menu>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>

      <v-dialog width="500px" v-model="$store.state.dialog"> 
          <v-card> 
              <v-card-title class="headline white--text" v-html="$store.state.dialog_title"></v-card-title> 
              <v-card-text v-html="$store.state.dialog_message" class="black--text pt-2"> </v-card-text> 
              <v-divider></v-divider> 
              <v-card-actions> 
                  <v-spacer></v-spacer> 
                  <v-btn corowlor="success" text @click="$store.commit('activateDialog', false)" > Close </v-btn> 
              </v-card-actions> 
          </v-card> 
      </v-dialog> 

      <div class="text-xs-center pa-0 ma-0">
          <v-snackbar
          multiLine
          :timeout="$store.state.snackBarTimeOut" 
          :top="y === 'top'"
          :bottom="y === 'bottom'"
          :right="x === 'right'"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :vertical="mode === 'vertical'"
          v-model="$store.state.snackBar" class="pa-0 ma-0"
          >
              <div v-html="$store.state.snackBarMessage"  class="pa-0 ma-0" >

              </div>
          </v-snackbar>
        </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0 text-center">
      <div class="boxed-container w-full">
        <div class="mx-6 text-center">
          <span class="text-center">
            &copy; 2018
            <a href="https://myapp.pettycashglobal.com" class="text-decoration-none"
              target="_blank">PettyCash</a></span>
        </div>
      </div>
    </v-footer>
    <v-bottom-navigation :value="value" color="primary" grow app fixed class="py-3" elevation="16">
      <v-btn v-for="item in bottomNavBarItems" :key="item.id" :to="item.link" @click="setChoosenProduct(item.id)" class="bottom_nav_button" plain>
        <span class="text-caption">{{ item.value }}</span>
        <font-awesome-icon :icon="item.icon" class="text-h5" />
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AppBarNotificationMenu from './components/AppBarNotificationMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    AppBarUserMenu,
    AppBarNotificationMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      multiLine: true,
      y: 'top',
      x: null,
      mode: '',
      timeout: 4000,

      isDrawerOpen,
      value: 0,
      bottomNavBarItems: [
        {
          id: '1',
          icon: 'fas fa-piggy-bank',
          value: 'Savings',
          link: '/savings/dashboard',
        },
        {
          id: '2',
          icon: 'fas fa-shopping-cart',
          value: 'Market Place',
          link: '/marketplace/dashboard',
        },
        {
          id: '3',
          icon: 'fas fa-hands-helping',
          value: 'Njangi',
          link: '/njangi/dashboard',
        },
        {
          id: '4',
          icon: 'fas fa-hand-holding-usd',
          value: 'Funding',
          link: '/fundraising/dashboard',
        },
        {
          id: '5',
          icon: 'fas fa-chart-line',
          value: 'Monitor',
          link: '/monitor/dashboard',
        },
      ],

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
      },
    }
  },
  
  mounted(){
      if (this.$store.state.isAuthenticated){
          this.getUserInfo()
          this.getUserNotifications() 
          this.$store.commit('setUnreadUserNotifications')
      }
  },

  methods: {
    logOut() {
        axios.defaults.headers.common['Authorization'] = ""
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        localStorage.removeItem('userid')
        this.$store.commit('removeToken')
        
        this.$store.commit('setSnackBarMessage', "You have logged out successfully")
        this.$store.commit('setSnackBarColor', "teal darken-4")
        this.$store.commit('activateSnackBar', true)
        let router = this.$router
        router.push('/')

    },
    async getUserNotifications() {
        await axios
            .get('/api/v1/home/get/user/notifications/')
            .then(response => {
                this.$store.state.user_notifications = response.data[0]  // get the data and fill into campaigns
                this.$store.state.unread_user_notifications = response.data[1]  // get the data and fill into campaigns
            })
            .catch(error => {
                if (error.response){
                    this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }else{
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            })
    },
    async getUserInfo() {
        if (this.$store.state.isAuthenticated && !this.$store.state.user_info_state){
            await axios
                .get('/api/v1/home/get/user/info/')
                .then(response => {
                  if (this.$store.state.username == ''){
                    this.$store.state.username = response.data[0]["user"]['username'] // get the data and fill into username
                  } 
                  this.$store.state.user = response.data[0]["user"]  // get the data and fill into username
                  this.$store.state.user_info_state = response.data[0]["user_info"]  // get the data and fill into username
                  this.$store.state.user_info = response.data[0]  // get the data and fill into username
                  if (!this.$store.state.user_info["user_profile"]["manager"]){
                    logOut()
                    this.$store.commit('setSnackBarMessage', "Sorry your account doesn't have the required priviledges to access this portal")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                  }
                })
                .catch(error => {
                    this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                })
        }
    },

    setChoosenProduct(item_id) {
      if (item_id == "1") {
        this.$store.commit('setIsSavings', true)
      }
      else if (item_id == "2") {
        this.$store.commit('setIsMarketPlace', true)
      }
      else if (item_id == "3") {
        this.$store.commit('setIsNjangi', true)
      }
      else if (item_id == "4") {
        this.$store.commit('setIsFundraising', true)        
      }
      else if (item_id == "5") {
        this.$store.commit('setIsMonitor', true)
      }
    this.$store.commit('setLocalStorageProduct')
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.bottom_nav_button {
  padding: 0 !important;
  min-width: 0 !important;
}
</style>
