import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false
    },
  },
  {
    path: '/savings/dashboard',
    name: 'savings.dashboard',
    component: () => import('@/views/savings/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/dashboard',
    name: 'marketplace.dashboard',
    component: () => import('@/views/marketplace/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/dashboard',
    name: 'njangi.dashboard',
    component: () => import('@/views/njangi/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/fundraising/dashboard',
    name: 'fundraising.dashboard',
    component: () => import('@/views/fundraising/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/monitor/dashboard',
    name: 'monitor.dashboard',
    component: () => import('@/views/monitor/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/deposit_requests',
    name: 'savings.deposit_requests',
    component: () => import('@/views/savings/deposit/Requests.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/deposit_transactions',
    name: 'savings.deposit_transactions',
    component: () => import('@/views/savings/deposit/DepositTransactions.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/make_deposits',
    name: 'savings.make_deposits',
    component: () => import('@/views/savings/deposit/MakeDeposit.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/partnership/requests',
    name: 'savings.partnership_requests',
    component: () => import('@/views/savings/partners/PartnershipRequests.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/partnership/clients',
    name: 'savings.partners_clients',
    component: () => import('@/views/savings/partners/PartnersClients.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/partnership/new_partner',
    name: 'savings.new_partner',
    component: () => import('@/views/savings/partners/NewPartner.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/make_withdrawals',
    name: 'savings.make_withdrawals',
    component: () => import('@/views/savings/withdrawal/MakeWithdrawal.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/request_withdrawal',
    name: 'savings.request_withdrawal',
    component: () => import('@/views/savings/withdrawal/RequestWithdrawal.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/withdrawal_requests',
    name: 'savings.withdrawal_requests',
    component: () => import('@/views/savings/withdrawal/Requests.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/withdrawal_transactions',
    name: 'savings.withdrawal_transactions',
    component: () => import('@/views/savings/withdrawal/WithdrawalTransactions.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/make_interrupt',
    name: 'savings.make_interrupt',
    component: () => import('@/views/savings/interrupt/MakeInterrupt.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/interrupt_requests',
    name: 'savings.interrupt_requests',
    component: () => import('@/views/savings/interrupt/Requests.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/interrupt_transactions',
    name: 'savings.interrupt_transactions',
    component: () => import('@/views/savings/interrupt/InterruptTransactions.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/profile/account-settings/:client_id',
    name: 'savings.profile-account-settings:client_id',
    component: () => import('@/views/savings/clients/account-settings/AccountSettings.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/summary',
    name: 'savings.clients',
    component: () => import('@/views/savings/clients/ClientList.vue'), meta: {
      requireLogin: true
    }
  },


  {
    path: '/savings/clients/campaign/:id',
    name: 'savings.client_campaign:id',
    component: () => import('@/views/savings/clients/CampaignDetail.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/loan/:id',
    name: 'savings.client_loan:id',
    component: () => import('@/views/savings/clients/LoanDetail.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/create',
    name: 'savings.create_client',
    component: () => import('@/views/savings/clients/CreateClient.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/create_campaign',
    name: 'savings.clients_create_campaign',
    component: () => import('@/views/savings/clients/CreateCampaign.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/zones',
    name: 'savings.clients_zones',
    component: () => import('@/views/savings/clients/TopUpZones.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/create_zone',
    name: 'savings.clients_create_zone',
    component: () => import('@/views/savings/clients/CreateZone.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/campaigns',
    name: 'savings.clients_campaigns',
    component: () => import('@/views/savings/clients/Campaigns.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/loans',
    name: 'savings.clients_loans',
    component: () => import('@/views/savings/clients/Loans.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/clients/create_loan',
    name: 'savings.clients_create_loan',
    component: () => import('@/views/savings/clients/CreateLoan.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/summary',
    name: 'savings.topup_summary',
    component: () => import('@/views/savings/topups/Partners.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/create',
    name: 'savings.topup_create',
    component: () => import('@/views/savings/topups/CreateTopUp.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/requested_password',
    name: 'savings.topup_requested_password',
    component: () => import('@/views/savings/topups/ResetPasswordRequests.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/change_password/:topup_request_id',
    name: 'savings.topup_change_password:topup_request_id',
    component: () => import('@/views/savings/topups/ResetPasswordForm.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/recharge',
    name: 'savings.topup_recharge',
    component: () => import('@/views/savings/topups/Recharge.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/transactions',
    name: 'savings.topup_transactions',
    component: () => import('@/views/savings/topups/Transactions.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/faults',
    name: 'savings.topup_faults',
    component: () => import('@/views/savings/topups/Faults.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/topup/faults/create',
    name: 'savings.topup_create_fault',
    component: () => import('@/views/savings/topups/CreateFault.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/investments',
    name: 'savings.investments',
    component: () => import('@/views/savings/investments/InvestmentsList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/investments/create',
    name: 'savings.create_investment',
    component: () => import('@/views/savings/investments/CreateInvestment.vue'), meta: {
      requireLogin: true
    }
  },


  {
    path: '/savings/salaries',
    name: 'savings.salaries',
    component: () => import('@/views/savings/salaries/SalaryList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/salaries/create',
    name: 'savings.create_salaries',
    component: () => import('@/views/savings/salaries/AddSalary.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/bills/create',
    name: 'savings.add_bills',
    component: () => import('@/views/savings/bills/AddBill.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/bills',
    name: 'savings.bills',
    component: () => import('@/views/savings/bills/BillsList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/employees_payments/create',
    name: 'savings.add_employee_payment',
    component: () => import('@/views/savings/employee_pay/AddEmployeePay.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/employees_payments',
    name: 'savings.employees_payments',
    component: () => import('@/views/savings/employee_pay/EmployeesPayList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/profits_commisions',
    name: 'savings.profits_commisions',
    component: () => import('@/views/savings/profits/ProfitsList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/marketing/traffic',
    name: 'savings.marketing_traffic',
    component: () => import('@/views/savings/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/prospects',
    name: 'savings.marketing_prospects',
    component: () => import('@/views/savings/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/view/prospects/:id',
    name: 'savings.marketing_view_prospects:id',
    component: () => import('@/views/savings/marketing_prospects/ProspectsDetails.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/prospects',
    name: 'savings.add_marketing_prospects',
    component: () => import('@/views/savings/marketing_prospects/AddProspects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/messages',
    name: 'savings.add_marketing_messages',
    component: () => import('@/views/savings/marketing_prospects/AddMessage.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/edit/messages/:id',
    name: 'savings.edit_marketing_messages:id',
    component: () => import('@/views/savings/marketing_prospects/EditMessage.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/send/ad_messages/:group_id/:message_id/:message_means/:start_date/:end_date',
    name: 'savings.edit_marketing_messages.:group_id.:message_id.:message_means.:start_date.:end_date',
    component: () => import('@/views/savings/marketing_prospects/SendAdMessageList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/resources',
    name: 'savings.marketing_resources',
    component: () => import('@/views/savings/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/resources/:key',
    name: 'savings.add_marketing_resources:key',
    component: () => import('@/views/savings/marketing_resources/AddResource.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/contact_us',
    name: 'savings.contact_us',
    component: () => import('@/views/savings/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/contact_us/reply/:id',
    name: 'savings.contact_us_reply:id',
    component: () => import('@/views/savings/contact_us/ContactUsChatReply.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/products',  // will contain all available orders and the topup can choose a confirmed order from the manager and then he delivers them
    name: 'marketplace.products',  // orders can also be paid here and it automatically becomes ur order
    component: () => import('@/views/marketplace/products/ProductList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/products/add',  
    name: 'marketplace.add_products',  
    component: () => import('@/views/marketplace/products/AddProduct.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/products/detail/:id',  
    name: 'marketplace.product_detail:id',  
    component: () => import('@/views/marketplace/products/settings/ProductSettings.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/orders',  
    name: 'marketplace.orders',  
    component: () => import('@/views/marketplace/orders/OrdersList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/orders/settings/:id',  
    name: 'marketplace.orders:id',  
    component: () => import('@/views/marketplace/orders/settings/Order.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/orders/add/town',  
    name: 'marketplace.add_order_town',  
    component: () => import('@/views/marketplace/orders/AddShippingTown.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/orders/add/shipping_point',  
    name: 'marketplace.add_order_shipping_point',  
    component: () => import('@/views/marketplace/orders/AddShippingPoint.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/products/settings/:product_id',
    name: 'marketplace.product_settings:product_id',
    component: () => import('@/views/marketplace/products/settings/ProductSettings.vue'), meta: {
      requireLogin: true
    }
  },
  
  {
    path: '/marketplace/transactions',  // view transactions (orders) only from his deliveries completed, pending delivery, cancelled by him
    name: 'marketplace.transactions',
    component: () => import('@/views/marketplace/transactions/TransactionsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/profits_commisions',  // view profits from his deliveries
    name: 'marketplace.profits_commisions',
    component: () => import('@/views/marketplace/profits/ProfitsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/faults',  // profits and commisions made by topup for the company through njangi
    name: 'marketplace.faults',
    component: () => import('@/views/marketplace/profits/Faults.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/faults/create',
    name: 'marketplace.create_fault',
    component: () => import('@/views/marketplace/profits/CreateFault.vue'), meta: {
      requireLogin: true
    }
   },

  {
    path: '/marketplace/marketing/traffic',
    name: 'marketplace.marketing_traffic',
    component: () => import('@/views/marketplace/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/marketing/prospects',
    name: 'marketplace.marketing_prospects',
    component: () => import('@/views/marketplace/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/marketing/resources',
    name: 'marketplace.marketing_resources',
    component: () => import('@/views/marketplace/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/contact_us',
    name: 'marketplace.contact_us',
    component: () => import('@/views/marketplace/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/contact_us/reply/:id',
    name: 'marketplace.contact_us_reply:id',
    component: () => import('@/views/marketplace/contact_us/ContactUsChatReply.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/join/requests',
    name: 'njangi.join_requests',
    component: () => import('@/views/njangi/groups/Requests.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/accept/requests',
    name: 'njangi.accept_requests',
    component: () => import('@/views/njangi/groups/AcceptRequest.vue'), meta: {
      requireLogin: true
    }
  },
  
  {
    path: '/njangi/create/group',
    name: 'njangi.create_group',
    component: () => import('@/views/njangi/groups/CreateNjangi.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/view/groups',
    name: 'njangi.view_groups',
    component: () => import('@/views/njangi/groups/ViewNjangiGroups.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/contribution_requests',
    name: 'njangi.contribution_requests',
    component: () => import('@/views/njangi/contribution/Requests.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/contribution_transactions',
    name: 'njangi.contribution_transactions',
    component: () => import('@/views/njangi/contribution/Transactions.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/make_contribution',
    name: 'njangi.make_contribution',
    component: () => import('@/views/njangi/contribution/MakeDeposit.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/marketing/traffic',
    name: 'njangi.marketing_traffic',
    component: () => import('@/views/njangi/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/marketing/prospects',
    name: 'njangi.marketing_prospects',
    component: () => import('@/views/njangi/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/marketing/resources',
    name: 'njangi.marketing_resources',
    component: () => import('@/views/njangi/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/contact_us',
    name: 'njangi.contact_us',
    component: () => import('@/views/njangi/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/profile/account-settings',
    name: 'profile-account-settings',
    component: () => import('@/views/authentication/account-settings/AccountSettings.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})



router.beforeEach((to, from, next) => {
  // to initialise authenticated
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    store.commit('initialiseStore')
  }
  
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {

    next({ name: 'login', query: { to: to.path } });
    store.commit('setSnackBarMessage', "Login is required: Please log into your account or request for the creation of one now.")
    store.commit('setSnackBarColor', "red darken-4")
    store.commit('activateSnackBar', true)
  } else {
    const token = store.state.token
    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
    console.log(to.path, from.path)
    next()
  }
})



export default router
