import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_notifications: [],
    user: {},
    username: '',
    current_app_feature: "",
    user_info_state: false,
    user_info: {},
    unread_user_notifications: 0,
    isAuthenticated: false,
    lengthActiveCampaigns: 0,
    token: '',
    isLoading: false,
    isSavings: false,
    isMarketPlace: false,
    isNjangi: false,
    isFundraising: false,
    isMonitor: false,
    isStatusPasswordRequestStatus: false,
    snackBar: false,
    snackBarColor: "",
    snackBarMessage: "",
    snackBarTimeOut: 4000,
    dialog: false,
    dialog_title: "",
    dialog_message: "",
    shopping_bag: {
      items: [],
    },
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }

      if (localStorage.getItem('isSavings')) {
        state.isSavings = JSON.parse(localStorage.getItem('isSavings'))
      } else {
        localStorage.setItem('isSavings', JSON.stringify(state.isSavings))
      }

      if (localStorage.getItem('isMarketPlace')) {
        state.isMarketPlace = JSON.parse(localStorage.getItem('isMarketPlace'))
      } else {
        localStorage.setItem('isMarketPlace', JSON.stringify(state.isMarketPlace))
      }

      if (localStorage.getItem('isNjangi')) {
        state.isNjangi = JSON.parse(localStorage.getItem('isNjangi'))
      } else {
        localStorage.setItem('isNjangi', JSON.stringify(state.isNjangi))
      }

      if (localStorage.getItem('isFundraising')) {
        state.isFundraising = JSON.parse(localStorage.getItem('isFundraising'))
      } else {
        localStorage.setItem('isFundraising', JSON.stringify(state.isFundraising))
      }

      if (localStorage.getItem('isMonitor')) {
        state.isMonitor = JSON.parse(localStorage.getItem('isMonitor'))
      } else {
        localStorage.setItem('isMonitor', JSON.stringify(state.isMonitor))
      }

      console.log('state.user')
    },

    addToShoppingBag(state, item) {
      const exists = state.shopping_bag.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.shopping_bag.items.push(item)
      }
      localStorage.setItem('shopping_bag', JSON.stringify(state.shopping_bag))
    },
    resetShoppingBag(state) {
      state.shopping_bag = {
        items: [],
      }
      localStorage.setItem('shopping_bag', JSON.stringify(state.shopping_bag))
    },

    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },

    removeToken(state, token){
        state.token = "" 
        state.isAuthenticated = false
    },

    setAppFeature(state, feature) {
      state.current_app_feature = feature
    },
    setIsLoading(state, status) {
      state.isLoading = status  // change the loading bar
    },
    setLocalStorageProduct(state){
      localStorage.setItem('isSavings', JSON.stringify(state.isSavings))
      localStorage.setItem('isMarketPlace', JSON.stringify(state.isMarketPlace))
      localStorage.setItem('isNjangi', JSON.stringify(state.isNjangi))
      localStorage.setItem('isFundraising', JSON.stringify(state.isFundraising))
      localStorage.setItem('isMonitor', JSON.stringify(state.isMonitor))
    },
    setIsSavings(state, status) {
      state.isSavings = status
      state.isMarketPlace = false
      state.isNjangi = false
      state.isFundraising = false
      state.isMonitor = false
    },
    setIsMarketPlace(state, status) {
      state.isSavings = false
      state.isMarketPlace = status
      state.isNjangi = false
      state.isFundraising = false
      state.isMonitor = false
    },
    setIsNjangi(state, status) {
      state.isSavings = false
      state.isMarketPlace = false
      state.isNjangi = status
      state.isFundraising = false
      state.isMonitor = false
    },
    setIsFundraising(state, status) {
      state.isSavings = false
      state.isMarketPlace = false
      state.isNjangi = false
      state.isFundraising = status
      state.isMonitor = false
    },
    setIsMonitor(state, status) {
      state.isSavings = false
      state.isMarketPlace = false
      state.isNjangi = false
      state.isFundraising = false
      state.isMonitor = status
    },

    setIsStatusPasswordRequestStatus(state, status) {
      state.isStatusPasswordRequestStatus = status
    },
    setLengthActiveCampaigns(state, length) {
      state.lengthActiveCampaigns = length
    },
    acceptedNjangiGroupInvitation(state, group_id) {
      state.acceptedNjangiGroup = true
      state.acceptedNjangiGroupId = group_id
    },
    acceptedNjangiEventInvitation(state, event_id) {
      state.acceptedNjangiEvent = true
      state.acceptedNjangiEventId = event_id
    },
    clearNjangiGroupInvitation(state) {
      state.acceptedNjangiGroup = false
      state.acceptedNjangiGroupId = ''
    },
    clearNjangiEventInvitation(state) {
      state.acceptedNjangiEvent = false
      state.acceptedNjangiEventId = ''
    },
    activateDialog(state, status) {
      state.dialog = status
    },
    setDialogTitle(state, title) {
      state.dialog_title = title
    },
    setDialogMessage(state, message) {
      state.dialog_message = message
    },
    activateSnackBar(state, status){
      state.snackBar = status  
    },
    setSnackBarMessage(state, message){
        state.snackBarMessage = message  
    },
    setSnackBarColor(state, color){
        state.snackBarColor = color  
    },
    setSnackBarTimeOut(state, timeout){
        state.snackBarTimeOut = timeout  
    },
    setUserNotifications(state) {
      axios
        .get('/api/v1/home/get/user/notifications/')
        .then(response => {
          state.user_notifications = response.data  // get the data and fill into campaigns
        })
        .catch(error => {
          console.log(error)
        })
    },
    setUnreadUserNotifications(state) {
      var no_unread_notifications = 0
      for (var notification in state.user_notifications) {
        if (notification.read) {
          console.log(no_unread_notifications)
          no_unread_notifications = no_unread_notifications + 1
        }
      }
      state.unread_user_notifications = no_unread_notifications
    },
  },
  actions: {}
})
